<template>
    <div id="about_mitone">
        <inc_header></inc_header>

        <div class="container">
            <div class="banner"><el-image :src="banner.srcPc" fit="cover"></el-image></div>

            <div class="show">
                <div class="left">
                    <div class="left_nav">
                        <router-link to="/about_mitone">关于觅堂</router-link>
                        <router-link to="/course_arrangement">课程安排</router-link>
                        <router-link to="/our_teachers">师资团队</router-link>
                        <router-link to="/class_story">课堂花絮</router-link>
                    </div>
                </div>
                <div class="right">
                    <h1>关于觅堂 <span>About MITONE</span></h1>
                    <div v-html="info.aboutMitone"></div>
                </div>
            </div>
        </div>

        <inc_footer></inc_footer>
    </div>
</template>

<style scoped>
    .banner { height:480px; }
    .banner .el-image { width:100%; height:100%; }

    .show { margin:60px 0; }
    .show:after { height:0; display:block; content:''; clear:both; visibility:hidden; }
    .show .left { width:310px; padding-right:40px; float:left; }
    .show .left .left_nav { width:100%; height:auto; padding:40px 0; background:#fcc; }
    .show .left .left_nav a { width:100%; height:40px; line-height:40px; border-bottom:1px solid #fff; color:#333; text-align:center; text-decoration:none; display:block; }
    .show .left .left_nav a:hover { color:#a00e15; font-weight:bold; background:#fff; }
    .show .left .left_nav a.focus { color:#a00e15; font-weight:bold; background:#fff; }
    .show .right { width:890px; float:left; }
    .show .right h1 { margin:0 0 30px 0; font-size:24px; }
    .show .right h1 span { color:#a00e15; }
    .show .right p { text-align:justify; }
    .show .right img { max-width:100%; }
</style>

<script>
    import inc_header from "../components/inc_header";
    import inc_footer from "../components/inc_footer";

    export default {
        name: "about_mitone",
        components: {
            inc_header,
            inc_footer
        },
        data() {
            return {
                banner: {},  // Banner
                info: {}
            }
        },
        created() {
            let that = this;

            // 获取“Banner”
            this.$axios.post(this.$global.config.serverApi+'/banner.php',{ action:"getByCategory", category:"aboutMitone" }).then( function(response) {
                if (response.data.complete === true) {
                    that.banner = response.data.banner;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });

            // 获取“关于觅堂”
            this.$axios.post(this.$global.config.serverApi+'/info.php',{ action:"get" }).then( function(response) {
                if (response.data.complete === true) {
                    that.info = response.data.info;
                } else {
                    throw new Error(response.data.error);
                }
            }).catch( function(error) {
                that.$store.commit('setAlert', error.message);  // 弹出错误提示
            });
        }
    }
</script>
